a {
  text-decoration: none;
  color: unset;
}

a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: unset;
}

* {
  scrollbar-color: #373e4f transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background-color: #373e4f;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5dbb46;
}
